<template>
  <!-- 表单管理 -->

  <div class="formment-box">
    <div class="box">
      <div class="top-nav">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>文章管理</el-breadcrumb-item>
          <el-breadcrumb-item class="active">添加文章</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="setbox">
        <div class="title">文章设置</div>
        <div class="inputbox">
          <el-form ref="form" :model="form" label-width="150px">
            <el-form-item label="文章标题">
              <el-input
                v-model="form.title"
                maxlength="30"
                placeholder="请输入文章标题"
              ></el-input>
            </el-form-item>
            <el-form-item label="副标题">
              <el-input
                v-model="form.subtitle"
                placeholder="请输入副标题"
                 maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item label="作者" >
              <el-input
                v-model="form.author"
                placeholder="请输入作者"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="排序">
              <el-input
                v-model="form.order"
                placeholder="请输入排序值"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="发布时间">
              <el-date-picker
                v-model="time"
                type="datetime"
                placeholder="设置未来时间可定时发布"
                clear-icon=""
                prefix-icon=""
                format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
              <!-- <el-input
                v-model="time"
                placeholder="设置未来时间可定时发布"
              ></el-input> -->
            </el-form-item>
            <div style="display: flex">
              <el-form-item label="是否显示">
                <el-switch v-model="is_show"></el-switch>
              </el-form-item>
              <!-- <el-form-item label="置顶">
                <el-switch v-model="is_top"></el-switch>
              </el-form-item>
              <el-form-item label="推荐">
                <el-switch v-model="is_recommend"></el-switch>
              </el-form-item> -->
            </div>
            <el-form-item label="分类目录">
              <!-- <el-switch v-model="is_file"></el-switch> -->
              <el-button size="small" type="primary" @click="choosefile"
                >分类目录</el-button
              >
            </el-form-item>
            <el-form-item label="缩略图">
              <el-upload
                class="upload-demo"
                :action="url"
                :limit="10"
                :on-success="uploadchange"
                :on-error="uploadchange"
                :file-list="smallimg"
                :show-file-list="false"
                list-type="picture"
              >
                <el-button size="small" type="primary">选择图片</el-button>
              </el-upload>
              <img
                v-if="form.thumbnailimg.url"
                :src="form.thumbnailimg.url"
                alt=""
                style="
                  width: 50px;
                  height: 50px;
                  margin: 5px 0;
                  vertical-align: top;
                "
              />
            
            </el-form-item>

            <el-form-item label="轮播多图">
              <el-upload
                class="upload-demo"
                :action="url"
                 :on-success="uploadenclosurechanges"
                :on-error="uploadenclosurechanges"
               
                :show-file-list="false"
                list-type="picture"
              >
                <el-button size="small" type="primary">选择图片</el-button>
              </el-upload>
                <div style="display:flex">
                 <div v-for="(item2,index2) in form.imgs" :key="index2" style="position: relative;margin: 5px 15px 5px 0;border: 1px solid #ccc;">
                   <img
                v-if="item2.url"
                :src="item2.url"
                alt=""
                style="
                  width: 60px;
                  height: 60px;
                  vertical-align: top;
                "
              />
                   <i class="el-icon-error" style="position: absolute;top:0;right:-8px;top:-4px;color:#333" @click="Remove(index2,0)"></i>
                 </div>
               </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="setbox">
        <div class="title">文章内容</div>
        <quill-editor
          ref="myQuillEditor"
          v-model="text"
          class="editor"
            :options="editorOption"
          @change="onEditorChange($event)"
        />
        <!-- <vue-ueditor-wrap v-model="dataStr" :config="myConfig" @beforeInit="addImageButton" @ready="ready"  class="editor" ></vue-ueditor-wrap> -->
      </div>
      <div class="setbox">
        <div class="title">附件设置</div>
        <div class="inputbox">
          <el-form ref="form" :model="form" label-width="150px">
            <el-form-item label="附件标题">
              <el-input
                v-model="form.enclosure_title"
                placeholder="请输入附件标题"
              ></el-input>
            </el-form-item>
            <el-form-item label="方案附件">
              <el-upload
                class="upload-demo"
                :action="url"
                :on-success="uploadenclosurechange"
                :on-error="uploadenclosurechange"
                :show-file-list="false"
                list-type="picture"
              >
                <el-button size="small" type="primary">选择附件</el-button>
                <span
                  class="el-upload__tip"
                  slot="tip"
                  style="margin-left: 15px; color: #999999"
                  >支持png、jpg、pdf、docx、pptx格式</span
                >
              </el-upload>
              <div>
                <div
                  v-for="(item2, index2) in form.enclosure_text"
                  :key="index2"
                  style="position: relative;border-bottom: 1px solid rgb(243 243 243);"
                >
                  <!-- <div style="display:flex;align-items: center;"> -->
                  <!-- <img  :src="item2.url" alt="" style="width:70px;height:70px;border:1px solid #999;margin:0 10px 10px 0"/> -->
                  <span>{{ item2.name }}</span>
                  <i
                    class="el-icon-delete"
                    style="margin-left: 10px"
                    @click="Remove(index2, 1)"
                  ></i>
                  <!-- </div> -->
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <el-button @click="previewclick">预览</el-button>
      <el-button type="primary" @click="btnclick" v-if="!addarticleMent"
        >添加</el-button
      >
      <el-button type="primary" @click="saveclick" v-else>保存</el-button>
    </div>
    <!-- 预览 -->
    <el-dialog title="预览" :visible.sync="dialogVisible" width="30%">
      
      <div class="preview-box">
        <div> <img src="@/assets/images/formimg/head-diy.png" alt="" style="width: 100%;"></div>
        <div class="preview-title">标题{{ form.title }}</div>
        <div class="title-info"><span>{{ form.author?form.author:'作者' }}</span><span>{{ form.create_time?form.create_time:getdateTime() }}</span><span>浏览量</span></div>
        <div v-html="text"></div>
      </div>
      <div
        style="
          display: flex;
          width: 350px;
          justify-content: space-evenly;
          margin: 20px auto 0;
        "
      >
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="分类目录" :visible.sync="dialogVisiblefile" width="50%">
      <!-- <div style="display: flex">
        <el-input v-model="createfilename" placeholder="请输入分类目录"></el-input>
      </div> -->
      <div  style="display:flex;align-items: center;height:30px;background: #fbf9f9;padding:0 10px;margin:10px 0">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item ><span @click="choosefile">目录</span></el-breadcrumb-item>
  <el-breadcrumb-item v-for="(item,index) in level_text" :key="index">
    <span  @click="choosefiles(item,index)">{{item[index+1].name}}</span></el-breadcrumb-item>
</el-breadcrumb>
      </div>
     <div style="display:flex;align-items: center;flex-wrap: wrap;">
       <div v-for="(item,index) in filelists" :key="index" style="margin:5px;border: 1px solid #DCDFE6;">
        <el-button size="small" @click="chooseitemfile(item)" style="border:0">{{item.name}}</el-button>
        
     </div>
     <div v-if="!filelists.length" style="height:40px;line-height:40px">文件夹为空</div>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblefile = false">取 消</el-button>
        <el-button type="primary" @click="createfile">确 定</el-button>
      </span>
    </el-dialog>
      <!-- 不需要显示,主要是将图片上传到服务器返回url -->
        <addImg @upimgSuccess="upimgSuccess" v-show="false"></addImg>
  </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
// import tinymce from '../../../public/tinymce/tinymce.min.js'
import VueUeditorWrap from "vue-ueditor-wrap";
import addImg from "@/components/addIimage/addImg";
import editorOption from "@/utils/noticeOption.js";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", ImageResize);
export default {
  data() {
    return {
      data: [],
      activefile:'',
      stopflag:false,
      level_text:[],
      editorOption: editorOption,
      url: "https://tw.huayeyun.cn/api/Admin/upload?platform_id=",
      dialogVisible: false,
      dialogVisiblefile: false,
      dataStr: "",
      text: "",
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: "100%",
        uploadUrl: process.env.VUE_APP_BASE_API + "",
        // 服务器地址,上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "https://tw.huayeyun.cn/php/controller.php",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "/UEditor/",
      },
      form: {
        text: "",
        title: "",
        enclosure_text: [],
        thumbnailimg: [],
        imgs:[],
        subtitle: "",
        author: "",
        enclosure_title: "",
      },
      is_file: false,
      time: "",
      is_show: true,
      is_recommend: true,
      is_top: true,
      smallimg: [],
filelists:[],
      fileList: [],
      createfilename: "",
      addarticleMent: false,
    };
  },
  watch: {
    text: {
      handler(val) {
        this.form.text = val.replace(/<img src/,"<img class='img' src")
        //   .replace(/<img/g, '<img class="img ')
        //   .replace(/<p/g, "<view")
        //   .replace(/p>/g, "view>");
        // console.log(
        //   val
        //     .replace(/<img/g, '<img class="img ')
        //     .replace(/<p/g, "<view")
        //     .replace(/p>/g, "view>")
        // );
        console.log( this.form.text);
      },
      deep: true,
    },
  },
  components: {
      addImg,
    "vue-ueditor-wrap": VueUeditorWrap,
  },
  created() {},
  mounted() {
    const platform_id = sessionGetKey("platformId");
    this.url =this.$uploadurl+ '?platform_id='+platform_id;
    this.getlist();
    this.addarticleMent = JSON.parse(window.localStorage.getItem("setarticle"));

    if (this.addarticleMent) {
      let text = JSON.parse(this.addarticleMent.text);
      this.form = {
        text: "",
        title: this.addarticleMent.name,
        enclosure_text: [],
        thumbnailimg: {
          url: this.addarticleMent.icon,
        },
        imgs:text.imgs?text.imgs:[],
        subtitle: text.subtitle,
        author: text.author,
        enclosure_title: text.enclosure_title,
        enclosure_text: text.enclosure_text,
      };
      this.is_show = this.addarticleMent.is_display;
      this.time = this.addarticleMent.time;
      this.text = text.text;
      this.activefile=this.addarticleMent.level_text
    }
  },
  methods: {
     choosefiles(e,i){
  this.level_text.splice(i+1)
  this.choosefile(e)
    },
        choosefile(e) {
      const platform_id = sessionGetKey("platformId");
      this.dialogVisiblefile = true;
      let that = this;
      this.stopflag=true
      let data={
        type:2
      }
      this.$request({
        url:
          this.$api.articleApi.getarticle + `?platform_id=${platform_id}`,
        method: "post",
        data: data,
      })
        .then((res) => {
          console.log(res);
          this.filelists=res.data
          if(!res.data.length){
            this.$message('请先创建分类目录')
          }
          this.level_text=[]
        })
        .catch((err) => {
          console.log(err);
        });
    },
     chooseitemfile(e){
      const platform_id = sessionGetKey("platformId");
      this.dialogVisiblefile = true;
      let that = this;
     let obj=new Object()
     obj[this.level_text.length+1]={id:e.id,name:e.name}
     if(this.stopflag){
        this.level_text.push(obj)
        this.stopflag = false
     }
     
     console.log(this.level_text)
      this.$request({
        url:
          this.$api.articleApi.getarticlelevel + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          id:e.id
        },
      })
        .then((res) => {
         that.filelists=res.data
           this.stopflag=true
        })
        .catch((err) => {
          console.log(err);
        });
    },
      upimgSuccess(imgUrl) {
            let quill = this.$refs.myQuillEditor.quill;
            let length = quill.getSelection().index;
            quill.insertEmbed(length, "image", imgUrl);
            quill.setSelection(length + 1);
        },

    uploadchange(res, file, fileList) {
      console.log(res, "----", file);
      if (res.code == 0) {
        let img = {
          name: file.name,
          url: file.response.data,
        };
        this.form.thumbnailimg = img;
      } else {
        this.$message("上传失败:" + file.response.msg);
      }
    },
    uploadenclosurechanges(res, file){
       if (res.code == 0) {
        let img = {
          name: file.name,
          url: file.response.data,
        };
        this.form.imgs.push(img);
        console.log(img);
      } else {
        this.$message("上传失败:" + file.response.msg);
      }
    },
    uploadenclosurechange(res, file, fileList) {
      console.log(res, "----", file);
      if (res.code == 0) {
        let img = {
          name: file.name,
          url: file.response.data,
        };
        this.form.enclosure_text.push(img);
        console.log(img);
      } else {
        this.$message("上传失败:" + file.response.msg);
      }
    },
    // Removes(){}
    Remove(i, val) {
      if (val == 0) {
        this.form.imgs.splice(i, 1);
      } else {
        this.form.enclosure_text.splice(i, 1);
      }
    },
    previewclick() {
      this.dialogVisible = true;
      console.log(this.dataStr, this.myConfig);
    },
    saveclick() {
      let that = this;
      console.log(this.addarticleMent);
      const platform_id = sessionGetKey("platformId");
      let data = {
        name: that.form.title,
        time: this.time,
        is_display: that.is_show ? 1 : 0,
        text: JSON.stringify(this.form),
        level_text: this.addarticleMent.level_text,
        icon: that.form.thumbnailimg.url,
        id: this.addarticleMent.id,
      };
       let obj = new Object();
      if (this.level_text.length) {
        this.level_text.forEach((val, index) => {
          obj[index + 1] = val[index + 1].id;
        });
        data.level_text = JSON.stringify([obj]);
      }
       console.log(this.level_text,this.activefile, data.level_text)
     
      if (this.time) {
        data.time = this.getdateTime(this.time);
      } else {
        data.time = this.getdateTime();
      }
      this.$request({
        url: this.$api.articleApi.setarticle + `?platform_id=${platform_id}`,
        method: "post",
        data: data,
      })
        .then((res) => {
          if (res.code == 0) {
            that.$message.success("保存成功");
              this.$router.push("articleMent");
          }
          console.log(res);
        })
        .catch((err) => {
          that.$message.error(err.message);
          console.log(err);
        });
    },
    btnclick(e) {
      let that = this;
      this.form.text = this.text;
      console.log(this.form, this.fileList, this.text);

      if(!that.form.title){
this.$message('请输入标题')
return
      }
      const platform_id = sessionGetKey("platformId");
      let data = {
        name: that.form.title,
        time: this.time,
        is_display: that.is_show ? 1 : 0,
        text: JSON.stringify(this.form),
        level_text: JSON.stringify(this.level_text),
        icon: that.form.thumbnailimg.url,
        platform_id: platform_id,
      };
     

      let obj = new Object();
      if (this.level_text.length) {
        this.level_text.forEach((val, index) => {
          obj[index + 1] = val[index + 1].id;
        });
        data.level_text = JSON.stringify([obj]);
      }
       console.log(data)
      
      if(!this.level_text.length){
         that.$message.error('请先分类');
        return
      }
      if (this.time) {
        data.time = this.getdateTime(this.time);
      } else {
        data.time = this.getdateTime();
      }
      console.log(data.time);

      console.log(this.form.text.replace(/p/g, "view"));
      this.$request({
        url: this.$api.articleApi.addarticle + `?platform_id=${platform_id}`,
        method: "post",
        data: data,
      })
        .then((res) => {
          if (res.code == 0) {
            that.$message.success("添加成功");
            this.$router.push("articleMent");
          }
          console.log(res);
        })
        .catch((err) => {
          that.$message.error(err.message);
          console.log(err);
        });
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
    },
    createfile(e) {
      //  添加文件夹
      this.dialogVisiblefile=false
    },
    getdateTime(val = new Date()) {
      let year = new Date(val).getFullYear();
      let month = new Date(val).getMonth() + 1;
      let day = new Date(val).getDate();
      let hour = new Date(val).getHours();
      let Minutes = new Date(val).getMinutes();
      let Seconds = new Date(val).getSeconds();
      return `${year}-${month > 9 ? month : "0" + month}-${
        day > 9 ? day : "0" + day
      } ${hour > 9 ? hour : "0" + hour}:${
        Minutes > 9 ? Minutes : "0" + Minutes
      }:${Seconds > 9 ? Seconds : "0" + Seconds}`;
    },
    getlist() {},
  },
};
</script>

<style lang="scss" scoped>
.btn-box {
  padding: 33px;
  background: #fff;
  margin-top: 30px;
}
.editor {
  margin: 20px;
  /deep/.ql-editor {
    height: 250px;
  }
  /deep/.ql-editing {
    left: 0 !important;
  }
}
.preview-box {
  width: 375px;
  height: 680px;
  padding: 10px 10px;
  overflow-y: auto;
  border: 1px solid #999;
  margin: 0 auto;
  border-radius: 20px;
  .title-info{
    span{
      margin-right: 5px;
    }
  }
  .preview-title {
    text-align: center;
    font-size: 21px;
    height: 30px;
  }
}
.formment-box {
  // height: 100%;
  padding: 66px;
  //   min-height: 600px;

  .setbox {
    margin-top: 20px;
    color: #333333;

    .title {
      background: #edefff;
      height: 35px;
      line-height: 35px;
      padding-left: 15px;
    }
    /deep/.el-input__inner {
      width: 820px;
    }
    /deep/.input-id {
      margin-left: 20px;
      width: 100px;
    }
    /deep/.input-id .el-input__inner {
      width: 100px;
    }
    /deep/.el-form-item__content {
      // display: flex;
      // align-items: center;
    }
    .inputbox {
      margin-left: 15%;

      margin-top: 24px;
    }
  }

  .box {
    // overflow: hidden;
    // position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
     padding: 33px;
      border-radius: 16px;
    .top-nav {
      display: flex;
      font-weight: 600;
      justify-content: space-between;
      align-items: center;
      /deep/.el-breadcrumb__item {
        padding-bottom: 10px;

        font-size: 20px !important;
        //
      }
      /deep/.active {
        color: #4458fe !important;
          font-weight: 600;
        border-bottom: #4458fe 2px solid;
        .el-breadcrumb__inner {
          color: #4458fe !important;
            font-weight: 600;
        }
      }
    }
  }
}
</style>